import React, { useEffect, useState } from "react";
import { IoWarning } from "react-icons/io5";
import { MdOndemandVideo } from "react-icons/md";
import { serverInstance } from "../../service/axios";
import Dimmer from "../shared/Dimmer";
import './training-list.scss';

const TrainingList = ({ data }) => {
  const categories = ["Эрдмийн чуулганы лекц", "Цахим хичээл"]
  const [searchText, setSearchText] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [years, setYears] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    serverInstance.get("/app/trainings")
      .then(res => {
        let data = res.data.items.filter(item => !item.test)
        setAllItems(data)
        setFilteredData(data)
        setErrorMsg("");
        setLoading(false);
        // Get unique years from data
        const years = Array.from(new Set(res.data.items?.map((item) => item.year))).sort();
        setYears(years)
      })
      .catch((err) => {
        setErrorMsg(err);
        setLoading(false);
      })
  }, []);

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      // Show all pages if total is 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Show limited pages with ellipsis
      if (currentPage <= 3) {
        for (let i = 1; i <= 3; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 2; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    return pageNumbers;
  };

  
  useEffect(() => {
    let result = allItems;

    // Text search filter
    if (searchText) {
      result = allItems.filter(
        (item) =>
          item.title.toLowerCase().includes(searchText.toLowerCase()) ||
          item.authors.some((author) =>
            author.toLowerCase().includes(searchText.toLowerCase())
          )
      );
    }

    // Keywords filter
    if (searchKeyword) {
      result = allItems.filter(
        (item) => item.keywords.some((word) =>
          word.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      )
    }

    // Category filter
    if (selectedCategory) {
      result = result.filter((item) => item.category == selectedCategory);
    }

    // Year filter
    if (selectedYear) {
      result = result.filter((item) => item.year == selectedYear);
    }

    setFilteredData(result);
  }, [searchText, selectedYear, searchKeyword, selectedCategory]);

  return (
    <div className='training-container container11 d-flex justify-content-center'>
      <h2>{data.title}</h2>

      {loading ? <Dimmer /> : null}

      {errorMsg ? <div className="error-msg">
        <IoWarning />
        &nbsp;
        {errorMsg}
      </div> : null}

      <div className='filters col-md-9 col-11'>
        <div className='filter-container'>
          <input
            type='text'
            placeholder={data.placeholder}
            className="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <select
            className="category-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value=''>{data.allCategory}</option>
            {categories.map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>      
        </div>

        <div className='filter-container'>
          <input
            type='text'
            placeholder={data.keyword}
            className="keyword-search"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <select
            className="year-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}>
            <option value=''>{data.allYears}</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="pagination d-flex justify-content-center flex-wrap">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-btn mx-1">
          {"<"}
        </button>

        {getPageNumbers().map((number, index) => (
          <button
            key={index}
            onClick={() => number !== '...' ? handlePageChange(number) : null}
            className={`pagination-btn mx-1 ${currentPage === number ? 'active' : ''} ${number === '...' ? 'dots' : ''}`}
          >
            {number}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-btn mx-1">
          {">"}
        </button>
      </div>

      <div className='col-md-9 col-11'>
        {currentItems.map((training) => (
          <div key={training.id} className='training-item'>
            <h3>{training.title}</h3>
            <div className='d-flex flex-column'>
              <span className='text-dark'>{data.author}: {training.authors?.join(", ")}</span>
              <span className='text-dark'>{data.summary}: {training.description}</span>
              <span className='text-dark'>{data.year}: {training.year}</span>
              <span className='text-dark'>{data.keyword}: [{training.keywords.join(", ")}]</span>
            </div>
            <a
              href={"/training/" + training.id}
              target='_blank'
              rel='noopener noreferrer'
              className='d-flex justify-content-end'>
              <MdOndemandVideo size={20} />

              {data.open}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingList;
