import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./PricingTable.scss";

const PricingTable = ({ data}) => {
  return (
    <div className="pricing-table">
      <h2>{data.title}</h2>
      {/* <p>
        {data.summary}
      </p> */}
      <div className="table-grid">
        <div className="header-cell"></div>
        {data?.plans.map((plan, index) => (
          <div key={index} className={`header-cell ` + plan.type}>
            <div className="type">{plan.type === "doctor" ? data.person : data.company} </div>
          </div>
        ))}

        <div className="data-cell">{data.term}</div>
        {data?.plans.map((plan, index) => (
          <div key={index} className={`footer-cell`}>
            <div className="term">{plan.term === 0 ? data.lifeLong : plan.term + " " + data.year}</div>
          </div>
        ))}

        <div className="data-cell">{data.price}</div>
        {data?.plans.map((plan, index) => (
          <div key={index} className={`footer-cell ${plan.name?.toLowerCase()}`}>
            <div className="price">{plan.price}</div>
          </div>
        ))}

        {data.features.map((feature, rowIndex) => (
          <>
            <div key={`feature-${rowIndex}`} className="feature-cell">
              <p>{feature}</p>
            </div>
            {data?.plans.map((plan, colIndex) => (
              <div key={`plan-${rowIndex}-${colIndex}`} className="data-cell">
                <FaCheckCircle className="feature-check"/>
                {/* {plan.features[rowIndex] === "-" ? (
                  <span className="no-feature">-</span>
                ) : (
                  <span className="feature-check">&#10003; {plan.features[rowIndex]}</span>
                )} */}
              </div>
            ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default PricingTable;
