// @ts-nocheck
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AnnualMeetingArchive from "./component/AnnualMeetingArchive";
import AnnualMeetings from "./component/AnnualMeetings";
import ChangePassword from "./component/client/ChangePassword";
import ClientAuthProvider from "./component/client/ClientAuthProvider";
import ClientPage from "./component/client/ClientPage";
import ForgotPassword from "./component/client/ForgotPassword";
import ResetPassword from "./component/client/ResetPassword";
import SignIn from "./component/client/SignIn";
import SignUp from "./component/client/SignUp";
import Clinic from "./component/Clinic";
import Conference from "./component/Conference";
import Doctor from "./component/Doctor";
import Donation from "./component/Donation";
import Events from "./component/Events";
import JournalCategories from "./component/journal/JournalCategories";
import JournalList from "./component/journal/JournalList";
import JournalReader from "./component/journal/JournalReader";
import Layout from "./component/Layout";
import { Main } from "./component/Main";
import NotFound from "./component/NotFound";
import PricingTable from "./component/PricingTable/PricingTable";
import Terms from "./component/Terms";
import Training from "./component/training/Training";
import TrainingList from "./component/training/TrainingList";
import ClientAuthContext from "./context/ClientAuthContext";
import data from "./data/data.json";

export const ThemeContext = createContext(null);

function App() {
  window.$ = window.jQuery = require("jquery");
  const [language, setLanguage] = useState(false);
  const [theme, setTheme] = useState([
    data.Theme.ThemeColor.colorOne,
    data.Theme.ThemeColor.colorTwo,
  ]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        localStorage.setItem("msid_app_lang", data.country_code)
        if (data.country_code === "MN") {
          setLanguage(false);
        } else {
          setLanguage(true);
        }
      } catch (error) {
        console.error("Error fetching the country code:", error);
      }
    };

    let msid_app_lang = localStorage.getItem("msid_app_lang")
    if (msid_app_lang) {
      if (msid_app_lang === "MN") {
        setLanguage(false);
      } else {
        setLanguage(true);
      }
    } else {
      fetchCountryCode();
    }
  }, []);
  return (
    <ThemeContext.Provider value={{ language, setLanguage, theme, setTheme }}>
      <ClientAuthContext.Provider value={{userData, setUserData}}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout data={data} language={language} />}>
            <Route path='/' element={<ClientAuthProvider />}>
              <Route index path='/' element={<Main data={data} language={language} />}/>
              <Route path='/client' element={<ClientPage data={language ? {...data.English.Client, ...data.English.SignUp} : {...data.Mongolian.Client, ...data.Mongolian.SignUp}}/>}/>
              <Route path="/client/signin" element={<SignIn data={language ? data.English.SignIn : data.Mongolian.SignIn}/>}/>
              <Route path='/training/:id' element={<Training data={language ? data.English.Training : data.Mongolian.Training}/>}/>
              <Route path="/change-password" element={<ChangePassword data={language ? data.English.SignUp : data.Mongolian.SignUp}/> }/>
              <Route path='journal/:id' element={<JournalReader data={language ? data.English.Journals : data.Mongolian.Journals} language={language ? "en" : "mn"} />}/>
            </Route>
            <Route path='/donation' element={
              <Donation data={language ? data.English.Donation : data.Mongolian.Donation}/>}
            />
            <Route path='/events' element={
              <Events data={language ? data.English.Events : data.Mongolian.Events}/>}
            />
            <Route path="/signup" element={
              <SignUp data={language ? data.English.SignUp : data.Mongolian.SignUp}/>}
            />
            <Route path="/forgot-password" element={
              <ForgotPassword data={language ? data.English.SignIn : data.Mongolian.SignIn}/> }
            />
            <Route path="/reset-password" element={
              <ResetPassword data={language ? data.English.SignUp : data.Mongolian.SignUp}/> }
            />
            <Route path='/membership' element={
              <PricingTable data={language ? data.English.Price : data.Mongolian.Price} />}
            />
            <Route path='/training' element={
              <TrainingList data={language ? data.English.Training : data.Mongolian.Training}/>}
            />
            <Route path='/conference' element={<Conference data={language ? data.English.Conference : data.Mongolian.Conference} />} />
            <Route path='/annual-meetings' element={
              <AnnualMeetings data={language ? data.English.AnnualMeetings : data.Mongolian.AnnualMeetings}/>}
            />
            <Route path='/archive' element={
              <AnnualMeetingArchive data={language ? data.English.MeetingArchive : data.Mongolian.MeetingArchive}/>}
            />
            <Route path='clinics' element={
              <Clinic data={language ? data.English.Clinics : data.Mongolian.Clinics} language={language ? "en" : "mn"} />}
            />
            <Route path='doctors' element={
              <Doctor data={language ? data.English.Doctors : data.Mongolian.Doctors} language={language ? "en" : "mn"} />}
            />
            <Route path='journal-categories' element={<JournalCategories data={language ? data.English.Journals : data.Mongolian.Journals} language={language ? "en" : "mn"}/>}/>
            <Route path='journals/archive' element={<JournalList data={language ? data.English.Journals : data.Mongolian.Journals} language={language ? "en" : "mn"}/>}/>
            <Route path='/terms' element={<Terms language={language ? "en" : "mn"} />} />
            <Route path='*' element={<NotFound />} /> 
          </Route>
        </Routes>
      </BrowserRouter>
      </ClientAuthContext.Provider>
    </ThemeContext.Provider>
  );
}
export default App;
