

import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ClientAuthContext from "../../context/ClientAuthContext";
import { serverInstance } from "../../service/axios";

const ClientAuthProvider = () => {

  const navigate = useNavigate();
  const {setUserData} = useContext(ClientAuthContext)
  
  useEffect(() => {
    const token = localStorage.getItem("msidclienttoken")
    if (token) {
      // console.log("Found token")
      serverInstance.post("/client", {}, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        setUserData(res.data.data)
      })
      .catch(err => {
        console.error(err)
      })
    }
    else if (window.location.pathname.startsWith("/client")) {
      navigate("/client/signin")
    }
  }, [])

  return <Outlet/>
};

export default ClientAuthProvider;

export const useAuth = () => {
  return useContext(ClientAuthContext);
};
