import AOS from "aos";
import "aos/dist/aos.css";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { SiFacebook, SiGmail, SiGooglemaps } from "react-icons/si";
import "../css/contact.scss";

const Contact = ({ data }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className='contact-container p-3 p-md-5'
      id='contact'
      >
      <div className='row w-100 d-flex justify-content-center'>
        <div className='logoContainer col-md-3 col-sm-10'>
          <img alt='logo' className='logo' src="/images/logo.png" />
        </div>
        <div className='contactContainer col-md-6 col-sm-10'>
          <div className='iconText'>
            <span className="location"><SiGooglemaps size={25} className='icons' /></span>
            <p>{data.location}</p>
          </div>
          <div className='iconText'>
            <SiGmail size={25} className='icons' />
            <p>{data.mail}</p>
          </div>
          <div className='iconText'>
            <FaPhoneAlt size={25} className='icons' />
            <p>{data.phoneNum}</p>
          </div>
          <div className='iconText'>
            <SiFacebook size={25} className='icons' />
            <a
              href={`${data.facebookAddress}`}
              target='blank'
              className='facebookLink w-75'>
              <p>Facebook page</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.shape({
    location: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    phoneNum: PropTypes.string.isRequired,
    facebookAddress: PropTypes.string.isRequired,
  }).isRequired,
};
export default Contact;
