import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Dimmer = () => {
  return (
    <div className='dimmer-container' style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', background: 'white', opacity: '0.8', zIndex: '10' }}>
      <AiOutlineLoading3Quarters size={25} className="loading-spinner"/>
    </div>
  );
}

export default Dimmer;