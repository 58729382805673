import React, { useState } from "react";
import { CiMail } from "react-icons/ci";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PiWarningCircleLight } from "react-icons/pi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { serverInstance } from "../../service/axios";
import Dimmer from "../shared/Dimmer";
import "./sign.scss";

const ResetPassword = ({ data }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    repassword: "",
    key: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    // Email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      setErrorMsg(
        data.invalidEmail ||
          "Мэйл хаягийг зөв бүтцээр оруулна уу. Жишээ нь: bat.saikhan@hospital.mn"
      );
      return false;
    }

    // Password validation
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(formData.password);
    const hasLowerCase = /[a-z]/.test(formData.password);
    const hasNumbers = /\d/.test(formData.password);

    if (formData.password.length < minLength) {
      setErrorMsg(
        data.passwordLength || "Password must be at least 8 characters long"
      );
      return false;
    }
    if (!hasUpperCase) {
      setErrorMsg(
        data.passwordUpperCase ||
          "Password must contain at least one uppercase letter"
      );
      return false;
    }
    if (!hasLowerCase) {
      setErrorMsg(
        data.passwordLowerCase ||
          "Password must contain at least one lowercase letter"
      );
      return false;
    }
    if (!hasNumbers) {
      setErrorMsg(
        data.passwordNumbers || "Password must contain at least one number"
      );
      return false;
    }

    // Password match validation
    if (formData.password !== formData.repassword) {
      console.log(data.passwordNotMatch);
      setErrorMsg(data.passwordNotMatch);
      return false;
    }

    if (!searchParams.get("key")) {
      setErrorMsg("Нууц үг сэргээх линк буруу байна");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true)
    serverInstance.post("/client/reset-password", {
      email: formData.email,
      password: formData.password,
      key: searchParams.get("key"),
    })
    .then((response) => {
      setLoading(false)
      setErrorMsg("")
      setSuccessMsg("Нууц үг амжилттай солигдлоо. Та шинэ нууц үгээ ашиглан нэвтэрнэ үү.")
    })
    .catch((error) => {
      console.error("Reset password error:", error);
      setErrorMsg(error?.response?.data?.message || "Хүсэлт илгээхэд алдаа гарлаа");
      setLoading(false)
    })
  };

  return (
    <div className="sign-container">
      <div className="sign-container-card">
        <h2>{data.newPassword}</h2>
        {loading ? <Dimmer/> : null}
        {successMsg ?
        <form className="sign-form" onSubmit={() => navigate("/client/signin")}>
          { successMsg ? <div className="form-div success-msg">
            <CiMail/>
            &nbsp;{successMsg}
          </div> : null }
          <div className="form-div">
            <button className="sign-button">Нэвтрэх хэсэг рүү очих</button>
          </div>
        </form>
        :
        <form className="sign-form" onSubmit={handleSubmit}>
          { errorMsg ?
          <div className="form-div error-msg">
            <PiWarningCircleLight/>
            &nbsp;{errorMsg}
          </div> : null }

          <div className="form-div">
            <label className="sign-label">{data.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          
          <div className="form-div">
            <label className="sign-label">{data.password}</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="sign-input"
            />
            <div className="password-container">
              {showPassword ? (
                <FaRegEye
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                />
              ) : (
                <FaRegEyeSlash
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                />
              )}
            </div>
          </div>
          <div className="form-div">
            <label className="sign-label">{data.repassword}</label>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                name="repassword"
                value={formData.repassword}
                onChange={handleChange}
                required
                className="sign-input"
              />
            </div>
          </div>
          
          <div className="form-div">
            <br/>
            <button type="submit" className="sign-button" disabled={loading}>
              {data.changePassword}
            </button>
          </div>
        </form>
        }
      </div>
    </div>
  );
};

export default ResetPassword;
