import React from "react";
import About from "./About";
import Contact from "./ContactUs";
import Gallery from "./Gallery";
import Header from "./Header";
import Home from "./Home";
import Services from "./Services";
import Team from "./Team";


export const Main = ({ data, language}) => {
  return (
    <>
      <Header
        title={language ? data.English.Title : data.Mongolian.Title}
        data={language ? data.English.Header : data.Mongolian.Header}
      />
      <Home data={language ? data.English.Home : data.Mongolian.Home} />
      <About data={language ? data.English.About : data.Mongolian.About} />
      <Team data={language ? data.English.Team : data.Mongolian.Team} />
      <Services
        data={language ? data.English.Services : data.Mongolian.Services}
      />
      <Gallery
        data={language ? data.English.Gallery : data.Mongolian.Gallery}
      />
      <Contact
        data={language ? data.English.Contact : data.Mongolian.Contact}
      />
    </>
  )
};