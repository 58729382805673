import React, { useContext, useEffect, useMemo, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { IoWarning } from "react-icons/io5";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useParams } from "react-router-dom";
import ClientAuthContext from "../../context/ClientAuthContext";
import { serverInstance } from "../../service/axios";
import "./pdf-reader.scss";
class JournalModel {
  id
  title
  category
  description
  fileUrl
  year
  authors
  keywords
  free_pages
}

const JournalReader= ({ data }) => {
  const { userData } = useContext(ClientAuthContext)
  const pageLimitDefault = 3;
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [journal, setJournal] = useState(new JournalModel());
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const options = useMemo(() => ({
    cMapUrl: '/cmaps/',
    useSystemFonts: true,
    renderAnnotationLayer: false,
    renderTextLayer: false,
  }), []);

  useEffect(() => {
    if (params.id && !journal.fileUrl) {
      serverInstance.post("/app/item", { class: "journal", id: params.id }, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        res.data.item.fileUrl = `https://msidassets.s3.ap-northeast-2.amazonaws.com/${res.data.item.fileUrl}`;
        res.data.item.free_pages = Number(res.data.item.free_pages) || pageLimitDefault;
        setJournal(res.data.item)
        setLoading(false);
        setErrorMsg("");
      })
      .catch((err) => {
        setLoading(false);
        setErrorMsg(err);
      })
    }
  }, [params.id]);

  useEffect(() => {
    setPageLimit(calculatePageLimit(totalPageCount));
  }, [userData, totalPageCount]);

  function calculatePageLimit(numPages) {
    let pageLimit = numPages;
    if (!userData?.first_name) {
      if (journal.free_pages < pageLimit) {
        pageLimit = journal.free_pages;
      }
    }
    return pageLimit;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setTotalPageCount(numPages);
  }

  const prev =() => {
    if (1 < pageNumber) {
      setPageNumber(pageNumber - 1)
    }
  }

  const next = () => {
    if (pageNumber < pageLimit) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <div className="pdf-reader">
      {loading ? <>
        <AiOutlineLoading3Quarters size={25} className="loading-spinner"/>
      </>
      :
      <>
        {errorMsg ?
        <div className="error-msg">
          <IoWarning/>
          &nbsp;
          {errorMsg}
        </div>: null}

        <div className="journal-title">{journal.title}</div>
        <div className="authors">{data.author}: {journal?.authors?.join(", ")}</div>
        <div className="category">{data.category}: {journal.category}</div>
        <div className="short">{data.short}: {journal.description}</div>
        <div className="year">{data.year}: {journal?.year}</div>
        <div className="keywords">{data.keyword}: {journal?.keywords?.join(", ")}</div>

        <div className="controls">
          <span className="arrow">
            <FaArrowLeft size={20} onClick={() => prev()}/>
          </span>
          <span className="page-number">{data.page} {pageNumber} / {pageLimit}</span>
          <span className="arrow">
            <FaArrowRight size={20} onClick={() => next()}/>
          </span>
        </div>

        { !userData?.first_name && pageNumber === pageLimit ?
        <div className="payment">{data.payment}</div> : null}
        
        <Document options={options} file={journal?.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page scale={96/72} pageNumber={pageNumber} />
        </Document>
      </>}
    </div>
  );
}

export default JournalReader;