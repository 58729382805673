import React, { useContext, useEffect, useState } from 'react';
import { LuAlignJustify } from "react-icons/lu";
import { TiUserOutline } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from "../App";
import ClientAuthContext from '../context/ClientAuthContext';
import "../css/header.scss";
import mn from "../data/icons/mongolia.png";
import eng from "../data/icons/united-kingdom.png";

const Header = ({ title, data }) => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(ClientAuthContext)
  const { setLanguage, theme, language } = useContext(ThemeContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [closingMenu, setClosingMenu] = useState(false);
  const [clientMenuOpen, setClientMenuOpen] = useState(false)
  const { innerWidth: width } = window;

  const toggleNavbar = () => {
    if (isCollapsed) {
      setClientMenuOpen(false);
    }
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isCollapsed) {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
  }, [isCollapsed]);

  const logout = () => {
    localStorage.removeItem("msidclienttoken");
    setUserData({});
    navigate("/")
  }

  function toggleMenu() {
    setClientMenuOpen(!clientMenuOpen)
    toggleNavbar();
  }

  function showClientMenu() {
    setClientMenuOpen(true)
  }

  function getOutOfClientMenu() {
    setClosingMenu(true)
    setTimeout(() => {
      if (closingMenu) {
        setClientMenuOpen(false)
      }
    }, 200);
  }

  return (
    <div
      className="header w-100"
      style={{
        backgroundImage: "linear-gradient(to right," + theme[1] + "," + theme[0] + ")",
      }}
    >
      <nav
        id="navbar"
        className="navbar navbar-expand-lg w-100 d-flex justify-content-between "
      >
        <a
          className='navbar-brand nav-logo text-wrap text-center'
          href='/#home'>
          {title}
        </a>

        <div className="d-flex">
          {width < 800 ?
            <>
              {language ? (
                <div
                  onClick={() => {
                    setLanguage(false);
                    localStorage.setItem("msid_app_lang", "MN");
                    setIsCollapsed(false);
                  }}
                >
                  <img alt="mn" src={eng} className="languageIcon" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setLanguage(true);
                    localStorage.setItem("msid_app_lang", "EN");
                    setIsCollapsed(false);
                  }}
                >
                  <img alt="eng" src={mn} className="languageIcon" />
                </div>
              )}
              <span>&nbsp;&nbsp;</span>
            </> : null}

          <button
            type="button"
            className="navbar-toggler collapsed"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={isCollapsed}
            aria-label="Toggle navigation"
          >
            <LuAlignJustify color="white" size={25} />
          </button>
        </div>

        <div
          className={`collapse navbar-collapse d-lg-flex justify-content-center ${isCollapsed ? "show" : ""
            }`}
          id="navbarNav"
        >
          <ul className="nav navbar-nav nav-pills d-lg-flex justify-content-md-end align-items-lg-center ">
            <a
              className='nav-link'
              href='/#home'
              onClick={() => setIsCollapsed(false)}>
              {data.home}
            </a>
            <a
              className='nav-link'
              href='/#about'
              onClick={() => setIsCollapsed(false)}>
              {data.about}
            </a>
            <a
              className='nav-link'
              href='/clinics'
              onClick={() => setIsCollapsed(false)}>
              {data.clinics}
            </a>
            <a
              className='nav-link'
              href='/doctors'
              onClick={() => setIsCollapsed(false)}>
              {data.doctors}
            </a>
            {/* <a
              className='nav-link'
              href='/#services'
              onClick={() => setIsCollapsed(false)}>
              {data.service}
            </a> */}
            {/* <a
              className='nav-link'
              href='/#team'
              onClick={() => setIsCollapsed(false)}>
              {data.team}
            </a> */}
            {/* <a
              className='nav-link'
              href='/#gallery'
              onClick={() => setIsCollapsed(false)}>
              {data.gallery}
            </a> */}
            <a
              className='nav-link'
              href='/#contact'
              onClick={() => setIsCollapsed(false)}>
              {data.contactUs}
            </a>
            <a
              className='nav-link'
              href='/donation'
              onClick={() => setIsCollapsed(false)}>
              {data.donation}
            </a>
            {width <= 800 ?
              <>
                <a
                  className='nav-link'
                  href='/clinics'
                  onClick={() => setIsCollapsed(false)}>
                  {data.clinics}
                </a>
                <a
                  className='nav-link'
                  href='/doctors'
                  onClick={() => setIsCollapsed(false)}>
                  {data.doctors}
                </a>
                <a
                  className='nav-link'
                  href='/training'
                  onClick={() => setIsCollapsed(false)}>
                  {data.training}
                </a>
                <a
                  className='nav-link'
                  href='/events'
                  onClick={() => setIsCollapsed(false)}>
                  {data.events}
                </a>
                <a
                  className='nav-link'
                  href='/journals'
                  onClick={() => setIsCollapsed(false)}>
                  {data.journals}
                </a>
                {/* <a
                  className='nav-link'
                  href='/conference'
                  onClick={() => setIsCollapsed(false)}>
                  {data.conference}
                </a>
                <a
                  className='nav-link'
                  href='/annual-meetings'
                  onClick={() => setIsCollapsed(false)}>
                  {data.annualMeetings}
                </a>
                <a
                  className='nav-link'
                  href='/archive'
                  onClick={() => setIsCollapsed(false)}>
                  {data.meetingArchive} */}
                {/* </a> */}
                {userData?.first_name ? null :
                <>
                <a
                  className='nav-link'
                  href='/client/signin'
                  onClick={() => setIsCollapsed(false)}>
                  {data.signin}
                </a>
                <a
                  className='nav-link'
                  href='/signup'
                  onClick={() => setIsCollapsed(false)}>
                  {data.signup}
                </a>
                </>}
              </>
              :
              <>
                {language ? (
                  <div
                    onClick={() => {
                      setLanguage(false);
                      setIsCollapsed(false);
                    }}
                  >
                    <img alt="mn" src={eng} className="languageIcon" />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setLanguage(true);
                      setIsCollapsed(false);
                    }}
                  >
                    <img alt="eng" src={mn} className="languageIcon" />
                  </div>
                )}
              </>
            }
            {userData?.first_name ?
              <div className='nav-link client-menu' onClick={() => navigate("/client")} 
              // onMouseEnter={showClientMenu} onMouseLeave={getOutOfClientMenu}
              >
                <span className="user-name">
                  <TiUserOutline size={25} />
                  {userData?.first_name}
                </span>
                {/* {clientMenuOpen ?
                  <div className="client-dropdown">
                    <div className="dropdown-item">
                      <Link to="/client" onClick={() => setClientMenuOpen(false)}>
                        <span className="menu-icon">
                          <TiUserOutline size={15} />
                        </span>
                        <span className="menu-text">Миний мэдээлэл</span>
                      </Link>
                    </div>
                    <div className="dropdown-item padding" onClick={() => logout()}>
                      <CiLogout size={15} />
                      <span className="menu-text">Вебсайтаас гарах</span>
                    </div>
                  </div> : null
                } */}
              </div> : null}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
