import React, { useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { serverInstance } from "../../service/axios";
import Dimmer from "../shared/Dimmer";
import "./sign.scss";

const SignUp = ({ data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);//TODO
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    graduated_year: "",
    institution: "",
    diploma_number: "",
    occupation: "",
    workplace: "",
    social_address: "",
    password: "",
    repassword: "",
    agreedTC: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    serverInstance
      .post("/client/signup", formData)
      .then((response) => {
        localStorage.setItem("msidclienttoken", response.data.token);
        navigate("/client");
      })
      .catch((err) => {
        setErrorMsg(
          err?.response?.data?.message || "Бүртгүүлэхэд алдаа гарлаа"
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorMsg(""); // Clear error message when user starts typing
  };

  const validate = () => {
    // Email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      setErrorMsg(
        data.invalidEmail ||
          "Мэйл хаягийг зөв бүтцээр оруулна уу. Жишээ нь: bat.saikhan@hospital.mn"
      );
      return false;
    }

    // Password validation
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(formData.password);
    const hasLowerCase = /[a-z]/.test(formData.password);
    const hasNumbers = /\d/.test(formData.password);

    if (formData.password.length < minLength) {
      setErrorMsg(
        data.passwordLength || "Password must be at least 8 characters long"
      );
      return false;
    }
    if (!hasUpperCase) {
      setErrorMsg(
        data.passwordUpperCase ||
          "Password must contain at least one uppercase letter"
      );
      return false;
    }
    if (!hasLowerCase) {
      setErrorMsg(
        data.passwordLowerCase ||
          "Password must contain at least one lowercase letter"
      );
      return false;
    }
    if (!hasNumbers) {
      setErrorMsg(
        data.passwordNumbers || "Password must contain at least one number"
      );
      return false;
    }

    // Password match validation
    if (formData.password !== formData.repassword) {
      console.log(data.passwordNotMatch);
      setErrorMsg(data.passwordNotMatch);
      return false;
    }

    // Mobile number validation
    const mobilePattern = /^[0-9]{8}$/;
    if (!mobilePattern.test(formData.mobile_number)) {
      setErrorMsg(
        data.invalidMobile || "Утасны дугаар 8 оронтой тоо байх ёстой"
      );
      return false;
    }

    // Graduation year validation
    const yearPattern = /^(19|20)\d{2}$/;
    if (!yearPattern.test(formData.graduated_year)) {
      setErrorMsg(data.invalidYear || "Төгссөн он буруу байна");
      return false;
    }

    // Required fields validation
    const requiredFields = [
      "first_name",
      "last_name",
      "institution",
      "diploma_number",
      "occupation",
      "workplace",
    ];

    for (const field of requiredFields) {
      if (!formData[field].trim()) {
        setErrorMsg(data[`${field}Required`] || `${field} is required`);
        return false;
      }
    }

    return true;
  };

  return (
    <div className="sign-container">
      <div className="sign-container-card">
        <h2>{data.signup}</h2>
        {loading ? <Dimmer/> : null}
        <form onSubmit={handleSubmit}>
          <div className="form-div">
            <label className="sign-label">{data.lname}</label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.fname}</label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.mnumber}</label>
            <input
              type="tel"
              name="mobile_number"
              pattern="[0-9]{8}"
              value={formData.mobile_number}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.institution}</label>
            <input
              type="text"
              name="institution"
              value={formData.institution}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.graduated_year}</label>
            <input
              type="text"
              name="graduated_year"
              value={formData.graduated_year}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.diploma_number}</label>
            <input
              type="text"
              name="diploma_number"
              value={formData.diploma_number}
              onChange={handleChange}
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.occupation}</label>
            <input
              type="text"
              name="occupation"
              value={formData.occupation}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.workplace}</label>
            <input
              type="text"
              name="workplace"
              value={formData.workplace}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.social_address}</label>
            <input
              type="text"
              name="social_address"
              value={formData.social_address}
              onChange={handleChange}
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.password}</label>
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="sign-input"
              />
              {showPassword ? (
                <FaRegEye
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                />
              ) : (
                <FaRegEyeSlash
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle"
                />
              )}
            </div>
          </div>
          <div className="form-div">
            <label className="sign-label">{data.repassword}</label>
            <input
              type={showPassword ? "text" : "password"}
              name="repassword"
              value={formData.repassword}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <br/>
            <input
              type="checkbox"
              required
              checked={formData.agreedTC}
              onChange={() =>
                setFormData({ ...formData, agreedTC: !formData.agreedTC })
              }
            />
            <span onClick={() => setFormData({ ...formData, agreedTC: true })}>
              {" "}
              {data.terms}
            </span>
            <Link to={"/terms"} target="_blank" className="terms-link">
              {data.link}
            </Link>
          </div>
          {errorMsg ? (
            <div className="form-div error-msg">
              <PiWarningCircleLight /> {errorMsg}
            </div>
          ) : null}
          <div className="form-div-row error">
            <br/>
            <button type="submit" className="sign-button" disabled={loading}>
              {data.signup}
            </button>
          </div>
          <br/>
          <div className="form-div sign-link">
            <Link to={"/client/signin"} className="sign-text">
              {data.textOne} <span className="sign-link">{data.textTwo}</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUp;
