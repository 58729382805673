import React, { useState } from "react";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { serverInstance } from "../../service/axios";
import Dimmer from "../shared/Dimmer";
import "./sign.scss";

const SignIn = ({ data }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);//TODO
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    serverInstance.post("/client/signin", formData)
      .then((response) => {
        localStorage.setItem("msidclienttoken", response.data.token);
        setLoading(false)
        navigate("/");
      })
      .catch((error) => {
        console.error("SignIn Error:", error);
        setErrorMsg(error?.response?.data?.message || "Нэвтрэхэд алдаа гарлаа");
        setLoading(false)
      })
  };

  return (
    <div className="sign-container">
      <div className="sign-container-card">
        <h2>{data.signin}</h2>
        {loading ? <Dimmer/> : null}
        <form className="sign-form" onSubmit={handleSubmit}>
          { errorMsg ? <div className="form-div error-msg">
            <PiWarningCircleLight/>
            &nbsp;{errorMsg}
          </div> : null }
          <div className="form-div">
            <label className="sign-label">{data.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <label className="sign-label">{data.password}</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          <div className="form-div">
            <br/>
            <button type="submit" className="sign-button" disabled={loading}>
              {data.signin}
            </button>
          </div>
          <br/>
          <div className="form-div signup-link">
            <Link to={"/signup"} className="sign-text">
              {data.textOne}{" "}
              <span className="sign-link">{data.textTwo}</span>
            </Link>
          </div>
          <div className="form-div signup-link">
            <Link to={"/forgot-password"} className="sign-text forgot">
              {data.forgotPassword}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
