import "aos/dist/aos.css";
import React from 'react';

const Terms = ({language}) => {

  return (
    <section>
      <div className="w-100 d-flex justify-content-center" style={{ "paddingTop": "150px" }}>
        <h3>{language === "mn" ? "Гишүүнчлэлийн нөхцөл" : "Terms and conditions"}</h3>
      </div>
      <div className="w-100 d-flex justify-content-center p-5" >
        {language === "mn" ?
            <div className="terms-content">
              <h4>1. Гишүүн болох нөхцөл</h4>
              <p>Төрийн бус байгууллагын гишүүнээр бүртгүүлэхийн тулд та:</p>
              <ul>
                <li>18-аас дээш насны</li>
                <li>Үнэн зөв, бүрэн гүйцэт мэдээлэл өгөх боломжтой байх</li>
                <li>АСМНийгэмлэгийн зорилго, зорилтыг хүлээн зөвшөөрсөн нийгэмлэгийн шийдвэрийг биелүүлэхэд идэвхтэй оролцдог арьсны эмч, арьс гоо заслын эмч мөн энэ чиглэлээр эрдэм шинжилгээ судалгаа хийж буй анагаах ухааны сургууль төгсөгчид, магистр, докторын зэрэг горилон суралцаж буй суралцагч, резидэнт эмч</li>
              </ul>

              <h4>2. Гишүүний үүрэг хариуцлага</h4>
              <ul>
                <li>Өөрийн мэдээллийг үнэн зөв, шинэчлэгдсэн байдлаар хадгалах</li>
                <li>Нийгэмлэгийн дүрэм, бодлого шийдвэрийг биелүүлэхийн төлөө идэвхитэй үйл ажиллагаа явуулах</li>
                <li>Нэвтрэх эрхийн мэдээллийг хамгаалж, бүртгэлийн аюулгүй байдлыг хангах</li>
                <li>Таны бүртгэлийг зөвшөөрөлгүй ашигласан тохиолдолд мэдэгдэх</li>
                <li>Сургалт бие даалтын хэлбэрүүдийг оновчтой ашиглан мэргэжлийн мэдлэг дадлагаа байнга дээшлүүлэх</li>
                <li>Гишүүний татварыг тогтоосон журмаар хугацаандаа өгч, нийгэмлэгийн хуримтлалд дэмжлэг үзүүлэх</li>
                <li>Нийгэмлэгийн дүрэм, журмыг ноцтой зөрчсөн, гишүүний татвараа удаан хугацаанд төлөөгүй гишүүнийг удирдах зөвлөлийн хурлаар хэлэлцэж гишүүнээс чөлөөлнө</li>
              </ul>

              <h4>3. Нууцлал ба өгөгдлийн хамгаалалт</h4>
              <p>Бүртгүүлснээр та дараах зүйлсийг зөвшөөрч байна:</p>
              <ul>
                <li>Таны байгууллагын мэдээллийг манай мэдээллийн санд хадгалах</li>
                <li>Үйлчилгээний шинэчлэлт болон мэдэгдлүүдийг тантай холбоо барих</li>
                <li>Таны мэдээллийг манай нууцлалын бодлогын дагуу боловсруулах</li>
              </ul>

              <h4>4. Ёс зүйн дүрэм</h4>
              <ul>
                <li>Бүх харилцаанд мэргэжлийн ёс зүйг баримтлах</li>
                <li>Бусад гишүүдийн эрх, нууцлалыг хүндэтгэх</li>
                <li>Ялгаварлан гадуурхах болон хор хөнөөл учруулах үйл ажиллагаа явуулахгүй байх</li>
              </ul>

              <h4>5. Гишүүнчлэлийг цуцлах</h4>
              <p>Дараах тохиолдолд гишүүнчлэлийг цуцална:</p>
              <ul>
                <li>Бүртгүүлэх үед худал мэдээлэл өгсөн</li>
                <li>Үйлчилгээний нөхцөлийг зөрчсөн</li>
                <li>Платформоор дамжуулан хууль бус үйл ажиллагаа явуулсан</li>
              </ul>
            </div>
          :
            <div className="terms-content">
              <h4>1. Membership Eligibility</h4>
              <p>To register as a non-governmental organization member, you must:</p>
              <ul>
                <li>Be at least 18 years of age</li>
                <li>Represent a legally registered non-governmental organization</li>
                <li>Provide accurate and complete registration information</li>
              </ul>

              <h4>2. Member Responsibilities</h4>
              <ul>
                <li>Maintain accurate and up-to-date organization information</li>
                <li>Comply with all applicable laws and regulations</li>
                <li>Protect access credentials and maintain account security</li>
                <li>Report any unauthorized use of your account</li>
              </ul>

              <h4>3. Privacy and Data Protection</h4>
              <p>By registering, you agree that:</p>
              <ul>
                <li>Your organization's information will be stored in our database</li>
                <li>We may contact you regarding service updates and notifications</li>
                <li>Your data will be handled according to our privacy policy</li>
              </ul>

              <h4>4. Code of Conduct</h4>
              <ul>
                <li>Maintain professional conduct in all interactions</li>
                <li>Respect the rights and privacy of other members</li>
                <li>Do not engage in discriminatory or harmful behavior</li>
              </ul>

              <h4>5. Termination</h4>
              <p>Membership may be terminated if:</p>
              <ul>
                <li>False information is provided during registration</li>
                <li>Terms and conditions are violated</li>
                <li>Illegal activities are conducted through the platform</li>
              </ul>
            </div>
        }
      </div>
    </section>
  );
};
export default Terms;
