import React, { useEffect, useState } from "react";
import { IoWarning } from "react-icons/io5";
import '../css/doctors.scss';
import { serverInstance } from "../service/axios";
import Dimmer from "./shared/Dimmer";

const Doctor = ({ data, language }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allItems, setAllItems] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    serverInstance.get("/app/doctors")
      .then(res => {
        let data = res.data.items.filter(item => !item.test)
        setAllItems(data)
        setFilteredData(data)
        setErrorMsg("");
        setLoading(false);
      })
      .catch((err) => {
        setErrorMsg(err);
        setLoading(false);
      })
  }, []);

  // Search handler function
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length >= 2) {
      const filtered = allItems.filter((item) => {
        return (
          (item.first_name.toLowerCase().includes(term)) ||
          (item.last_name.toLowerCase().includes(term)) ||
          (item.workplace && item.workplace.toLowerCase().includes(term)) ||
          (item.occupation && item.occupation.toLowerCase().includes(term))
        );
      });
      setFilteredData(filtered);
    }
  };

  return (
    <div className='doctors-container container11 d-flex justify-content-center' id='doctors'>
      <div className='fs-3 fw-bold doctors-title'>{data.title}</div>
      
      {loading ? <Dimmer/> : null}
      
      {errorMsg ? <div className="error-msg">
        <IoWarning/>
        &nbsp;
        {errorMsg}
      </div> : null}
      
      <input
        type='text'
        placeholder={data.placeholder}
        value={searchTerm}
        onChange={handleSearch}
        className='mt-3 mb-3 p-2 col-md-9 col-11 mb-5'
      />

      <div className='col-11 col-sm-9 row'>
        {filteredData.map((item) => (
          <div key={"doctors" + item.id} className='doctor-item col-6 col-sm-6 col-md-4 col-lg-3 d-flex flex-column justify-content-start align-items-center'>
            <div
              className='rounded-circle d-flex justify-content-center align-items-center'
              style={{ objectFit: "cover", width: 150, height: 150 }}>
              <img
                src={item.fileUrl? `https://msidassets.s3.ap-northeast-2.amazonaws.com/${item.fileUrl}` : "/images/team/member.png"}
                alt={item.first_name}
              />
            </div>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center mt-3'>
              <span className='text-dark text-center fw-bold'>
              {item.last_name.slice(0,1)}.{item.first_name}
              </span>
              <span className='text-muted text-center'>{item.occupation},</span>
              <span className='text-center fw-bold'>{item.workplace}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Doctor;
