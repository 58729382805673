import React, { useState } from "react";
import { CiMail } from "react-icons/ci";
import { PiWarningCircleLight } from "react-icons/pi";
import { serverInstance } from "../../service/axios";
import Dimmer from "../shared/Dimmer";
import "./sign.scss";

const ForgotPassword = ({ data }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);//TODO
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    serverInstance.post("/client/forgot-password", formData)
      .then((response) => {
        setLoading(false)
        setErrorMsg("")
        setSuccessMsg("Нууц үг сэргээх линкийг таны мэйл хаягаар илгээлээ.")
      })
      .catch((error) => {
        console.error("Forgot password error:", error);
        setErrorMsg(error?.response?.data?.message || "Хүсэлт илгээхэд алдаа гарлаа");
        setLoading(false)
      })
  };

  return (
    <div className="sign-container">
      <div className="sign-container-card">
        {/* <h2>{data.signin}</h2> */}
        {loading ? <Dimmer/> : null}
        <form className="sign-form" onSubmit={handleSubmit}>
          { errorMsg ? <div className="form-div error-msg">
            <PiWarningCircleLight/>
            &nbsp;{errorMsg}
          </div> : null }
          
          { successMsg ? <div className="form-div success-msg">
            <CiMail/>
            &nbsp;{successMsg}
          </div> : null }
          
          <div className="form-div">
            <label className="sign-label">{data.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="sign-input"
            />
          </div>
          
          <div className="form-div">
            <br/>
            <button type="submit" className="sign-button" disabled={loading}>
              {data.signin}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
