import React, { useContext, useEffect, useState } from "react";
import { CiCircleCheck, CiLogout } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import ClientAuthContext from "../../context/ClientAuthContext";
import { serverInstance } from "../../service/axios";
import { isEmpty } from "../../utils/Utils";
import Dimmer from "../shared/Dimmer";
import "./client.scss";

const ClientPage = ({ data }) => {

  const bucket = "msidassets"
  const region = "ap-northeast-2"
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(ClientAuthContext)
  const [unsavedData, setUnsavedData] = useState({ first_name: "", last_name: "", email: "", mobile_number: "", institution: "", graduated_year: "", diploma_number: "", workplace: "", occupation: "", social_address: "", "fileUrl": "" })
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem("msidclienttoken")
    if (token) {
      serverInstance.post("/client", {}, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
        .then(res => {
          setUserData(res.data.data)
          setUnsavedData(res.data.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, []);

  const getUploadUrl = async () => {
    try {
      let response = await serverInstance.post("/client/upload", { key: unsavedData.fileUrl},
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("msidclienttoken")}`,
            'Content-Type': 'application/json'
          }
        }
      )
      if (response.status === 200) {
        let uploadUrl = response.data.uploadUrl;
        return uploadUrl;
      }
      return undefined
    }
    catch (err) {
      console.error(err);
      return undefined
    }
  }

  const uploadFile = async (uploadUrl) => {
    try {
      let fileBlob = new Blob([file], { type: file.type });
      let response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          ContentType: file.type,
        },
        body: fileBlob
      })
      console.log("Image upload status", response);
      return true;
    }
    catch (err) {
      console.error(err)
      return false;
    }
  }

  const deleteOldImage = async (key) => {
    try {
      await serverInstance.delete("/client/upload", {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("msidclienttoken")}`,
          'Content-Type': 'application/json'
        },
        data: { key: key }
      });
    } catch (err) {
      console.error(err);
    }
  }
  
  const validate = () => {
    if (!unsavedData.last_name) {
      setErrorMsg(data.lname + " оруулна уу")
      return false
    }
    if (!unsavedData.first_name) {
      setErrorMsg(data.fname + " оруулна уу")
      return false
    }
    if (!unsavedData.mobile_number) {
      setErrorMsg(data.mnumber + " оруулна уу")
      return false
    }
    if (!unsavedData.institution) {
      setErrorMsg(data.institution + " оруулна уу")
    }
    if (!unsavedData.graduated_year) {
      setErrorMsg(data.graduated_year + " оруулна уу")
    }
    if (!unsavedData.diploma_number) {
      setErrorMsg(data.diploma_number + " оруулна уу")
    }
    if (!unsavedData.workplace) {
      setErrorMsg(data.workplace + " оруулна уу")
    }
    if (!unsavedData.occupation) {
      setErrorMsg(data.occupation + " оруулна уу")
    }
    if (!unsavedData.social_address) {
      setErrorMsg(data.social_address + " оруулна уу")
    }
    // if (!unsavedData.fileUrl) {
    //   setErrorMsg(data.fileUrl + " оруулна уу")
    // }
    return true
  }

  const save = async (event) => {
    event.preventDefault();
    if (!validate()) {
      return
    }
    setSuccessMsg("")
    setErrorMsg("")
    setLoading(true)

    if (unsavedData.fileUrl && userData.fileUrl != unsavedData.fileUrl) {
      let uploadUrl = await getUploadUrl();

      if (isEmpty(uploadUrl)) {
        setErrorMsg("Зураг хуулахад үед алдаа гарлаа")
        setLoading(false)
        return false;
      }

      let result = await uploadFile(uploadUrl)
      setUploaded(result)

      if (!result) {
        setErrorMsg("Зураг хуулахад алдаа гарлаа")
        setLoading(false)
        return
      }
    }

    serverInstance.put("/client", unsavedData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("msidclienttoken")}`,
        'Content-Type': 'application/json'
      }
    }).then(res => {
      let imageToDelete = userData.fileUrl && userData.fileUrl !== unsavedData.fileUrl ? userData.fileUrl : null;
      setUserData(unsavedData)
      setErrorMsg("")
      setSuccessMsg("Амжилттай хадгаллаа.")
      setLoading(false)
      if (uploading) {
        setUploading(false)
      }
      if (imageToDelete) {
        deleteOldImage(imageToDelete);
      }
    })
      .catch(err => {
        console.error(err)
        setErrorMsg(err?.response?.data?.message || "Хадгалахад алдаа гарлаа");
        setLoading(false)
      })
  }

  const generateFileKeyInS3 = (fileName) => {
    let fileExt = fileName.split(".").pop();
    return `client/${userData.id}.${fileExt}`;
  }

  const fileChanges = (e) => {
    setFile(e.target.files[0])
    setUnsavedData({
      ...unsavedData,
      fileUrl: generateFileKeyInS3(e.target.files[0].name)
    })
  }

  const logout = () => {
    localStorage.removeItem("msidclienttoken");
    setUserData({});
    navigate("/")
  }

  return (
    <div className='client-container'>
      { userData?.first_name ?
        <div className="client-header">
          <div className="log-out" onClick={() => logout()}>
            <CiLogout size={15} />
            <span className="menu-text">Вебсайтаас гарах</span>
          </div>
        </div>
      : null }

      <h1 className='title'>{data.title}</h1>

      <section className='client-details'>
        {loading ? <Dimmer /> : null}

        <form onSubmit={save}>
          {userData.fileUrl && !uploading ?
          <div className="section-row">
            <div className="file-preview">
              <div className='rounded-circle d-flex justify-content-center'
                style={{ objectFit: "cover", width: 150, height: 150 }}>
                <img src={`https://${bucket}.s3.${region}.amazonaws.com/${unsavedData.fileUrl}`} alt="Зургийн линк"/>
              </div>
              <span className="upload-image" onClick={() => setUploading(true)}>
                <IoCloseCircleOutline size={20}/>
                &nbsp;Зураг солих
              </span>
            </div>
          </div> : null}

          { !userData.fileUrl || uploading ?
          <div className="section-row">
            <div className='rounded-circle d-flex justify-content-center'
              style={{ objectFit: "cover", width: 150, height: 150 }}>
              <img src="/images/team/member.png" alt="Зургийн линк"/>
            </div>
            <label>Зураг (1x1 хэмжээтэй):</label>
            <input
              type="file"
              name="file"
              accept="image/*"
              onChange={(e) => fileChanges(e)}
            />
            {userData.fileUrl && uploading ? <span className="recover-file" onClick={() => setUploading(false)}>Файл сэргээх</span> : null}
          </div> : null }

          <hr/>

          <div className="section-row">
            <label>{data.lname}</label>
            <input type="text" name="last_name" value={unsavedData?.last_name} onChange={(e) => setUnsavedData({ ...unsavedData, last_name: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.fname}</label>
            <input type="text" name="first_name" value={unsavedData?.first_name} onChange={(e) => setUnsavedData({ ...unsavedData, first_name: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.mnumber}</label>
            <input type="text" name="mobile_number" value={unsavedData?.mobile_number} onChange={(e) => setUnsavedData({ ...unsavedData, mobile_number: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.email}</label>
            <input type="text" name="email" className="disabled" disabled value={userData?.email} />
          </div>
          <div className="section-row">
            <label>{data.institution}</label>
            <input type="text" name="institution" value={unsavedData?.institution} onChange={(e) => setUnsavedData({ ...unsavedData, institution: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.graduated_year}</label>
            <input type="number" name="graduated_year" value={unsavedData?.graduated_year} onChange={(e) => setUnsavedData({ ...unsavedData, graduated_year: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.diploma_number}</label>
            <input type="text" name="diploma_number" value={unsavedData?.diploma_number} onChange={(e) => setUnsavedData({ ...unsavedData, diploma_number: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.workplace}</label>
            <input type="text" name="workplace" value={unsavedData?.workplace} onChange={(e) => setUnsavedData({ ...unsavedData, workplace: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.occupation}</label>
            <input type="text" name="occupation" value={unsavedData?.occupation} onChange={(e) => setUnsavedData({ ...unsavedData, occupation: e.target.value })} />
          </div>
          <div className="section-row">
            <label>{data.social_address}</label>
            <input type="text" name="social_address" value={unsavedData?.social_address} onChange={(e) => setUnsavedData({ ...unsavedData, social_address: e.target.value })} />
          </div>
          
          {errorMsg ? <div className="section-row error">
            <PiWarningCircleLight />
            &nbsp;
            {errorMsg}
          </div> : null}
          {successMsg ? <div className="section-row success-msg">
            <CiCircleCheck />
            &nbsp;
            {successMsg}
          </div> : null}
          <div className="section-row">
            <button type="submit" disabled={loading}>{data.save}</button>
          </div>
          <br/>
          <div className="section-row">
            <Link to="/change-password" className="form-link">{data.changePassword}</Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ClientPage;
