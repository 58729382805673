function generateId() {
  return Date.now();
}

function isEmpty(value: any) {
  return value === undefined || value === null || value === '';
}

function isNotEmpty(value: any) {
  return value !== undefined && value !== null && value !== '';
}

export {
  generateId,
  isEmpty,
  isNotEmpty
};
