import React from "react";
import { useNavigate } from "react-router-dom";
import "./category.scss";

const JournalCategories = ({ data }) => {

  const navigate = useNavigate();

  return (
    <div className='category-container'>
      <h2>{data.title}</h2>

      <section className="category-list">
        <div className='categories col-md-10 col-12'>
          {data.categories.slice(0, 3).map((category, index) => {
            return (
              <div className='category-item col-10 col-md-3' key={index} 
                onClick={() => window.open(data.categoryLinks[index])}>
                <div className='category-icon'>
                  <i className='fas fa-circle'></i>
                </div>
                <div className='category-name'>
                  {category}
                </div>
              </div>
            )
          })}
        </div>
        <div className='categories col-md-10 col-12'>
          {data.categories.slice(3, 5).map((category, index) => {
            return (
              <div className='category-item col-10 col-md-3' key={index+3}
                onClick={() => window.open(data.categoryLinks[index + 3])}>
                <div className='category-icon'>
                  <i className='fas fa-circle'></i>
                </div>
                <div className='category-name'>
                  {category}
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default JournalCategories;
