import React, { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoWarning } from "react-icons/io5";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import ClientAuthContext from "../../context/ClientAuthContext";
import "../../css/training.scss";
import { serverInstance } from "../../service/axios";

class TrainingModel {
  id
  title
  description
  year
  authors
  keywords
  fileUrl
}

const Training= ({ data }) => {
  const params = useParams();
  const { userData } = useContext(ClientAuthContext)
  const [training, setTraining] = useState(new TrainingModel());
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if (params.id && !training.fileUrl) {
        serverInstance.post("/app/item", { class: "training", id: params.id }, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then(res => {
          res.data.item.fileUrl = `https://msidassets.s3.ap-northeast-2.amazonaws.com/${res.data.item.fileUrl}`;
          setTraining(res.data.item)
          setLoading(false);
          setErrorMsg("");
        })
        .catch((err) => {
          setLoading(false);
          setErrorMsg(err);
        })
      }
    }, [params.id]);

  return (
    <div className="training">
      {loading ? <>
        <AiOutlineLoading3Quarters size={25} className="loading-spinner"/>
      </>
      :
      <>
        {errorMsg ?
        <div className="error-msg">
          <IoWarning/>
          &nbsp;
          {errorMsg}
        </div>: null}

        <div className="title">{training.title}</div>
        <div className="authors">{data.author}: {training?.authors?.join(", ")}</div>
        <div className="short">{data.summary}: {training.description}</div>
        <div className="year">{data.year}: {training?.year}</div>
        <div className="keywords">{data.keyword}: {training?.keywords?.join(", ")}</div>
        { userData?.first_name ?
          <div className="video-container">
            <ReactPlayer className="video-player" 
              controls={true} 
              url={training.fileUrl} 
              fallback={<AiOutlineLoading3Quarters 
              size={25}/>}/>
          </div>
          :
          <div className="payment">
            {data.payment}
          </div>
        }
      </>}
    </div>
  );
}

export default Training;