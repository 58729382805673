import AOS from "aos";
import "aos/dist/aos.css";
import React, { useContext, useEffect, useState } from "react";
import { CiLogout } from "react-icons/ci";
import { TiUserOutline } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import ClientAuthContext from "../context/ClientAuthContext";
import "../css/home.scss";
import { serverInstance } from "../service/axios";
import Dimmer from "./shared/Dimmer";

const Home = ({ data }) => {

  const navigate = useNavigate();
  const { userData, setUserData } = useContext(ClientAuthContext)
  const [loading, setLoading] = useState(false);
  const [leftLinks, setLeftLinks] = useState([]);
  const [rightLinks, setRightLinks] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    AOS.init();

    const token = localStorage.getItem("msidclienttoken")
    if (token) {
      setLoading(true)
      serverInstance.post("/client", {}, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        setLoading(false)
        setUserData(res.data.data)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
      })
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const leftSide = {
      menu: userData?.first_name ? data.leftMenu.slice(4, 8) : data.leftMenu.slice(0, 4),
      links: userData?.first_name ? data.leftLinks.slice(4, 8) : data.leftLinks.slice(0, 4)
    };
        
    const rightSide = {
      menu: userData?.first_name ? data.rightMenu.slice(4, 8) : data.rightMenu.slice(0, 4),
      links: userData?.first_name ? data.rightLinks.slice(4, 8) : data.rightLinks.slice(0, 4)
    };
  
    // Create the combined link objects
    const rightObjs = leftSide.menu.map((menu, index) => ({
      text: menu,
      link: leftSide.links[index]
    }));
  
    const leftObjs = rightSide.menu.map((menu, index) => ({
      text: menu,
      link: rightSide.links[index]
    }));
  
    setLeftLinks(rightObjs);
    setRightLinks(leftObjs);
  }, [data, userData]);

  const logout = () => {
    localStorage.removeItem("msidclienttoken");
    setUserData({});
    navigate("/")
  }

  return (
    <div className="home-body" id="home">
      {loading ? <Dimmer/> : null}
      { userData?.first_name ?
        <div className="home-header">
          { width <= 768 ?
          <div className="user-name" onClick={() => navigate("/client")}>
            <TiUserOutline size={25} />
            {userData?.first_name}
          </div>
          : null }
          <div className="log-out" onClick={() => logout()}>
            <CiLogout size={15} />
            <span className="menu-text">Вебсайтаас гарах</span>
          </div>
        </div>
      : null }
      
      <div className="home-inner">
        {width <= 768 ? (
          <>
            {rightLinks.map((item, index) => (
              <Link key={index} className="home-real-button" to={item.link}>
                {item.text}
              </Link>
            ))}
          </>
        ) : (
          <></>
        )}
        <div className="home-img-cntnr">
          {width > 768 ? (
            <>
              {width > 768 ? (
                <>
                  {rightLinks.map((item, index) => (
                    <div key={index} className={`home-button right-home-button-${index + 1}`} data-aos='fade-left'>
                      <div className="home-circle">
                        <Link className="home-text" to={item.link}>
                          <div className="link-text link-text-right">
                            {item.text}
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}            </>
          ) : (
            <></>
          )}

          <img src={data.image} className="mainImg" alt="profile" />

          {width > 768 ? (
            <>
              {leftLinks.map((item, index) => (
                <div key={index} className={`home-button left-home-button-${index + 1}`} data-aos='fade-right'>
                  <div className="home-circle home-circle-left">
                    <Link className="home-text home-text-left" to={item.link}>
                      <div className="link-text link-text-left">
                        {item.text}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}        </div>
        
        {width <= 768 ? (
          <>
            {leftLinks.map((item, index) => (
              <Link key={index} className="home-real-button" to={item.link}>
                {item.text}
              </Link>
            ))}          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Home;
