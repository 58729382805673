import React, { useState } from "react";
import { IoWarning } from "react-icons/io5";
import "../css/clinic.scss";
import clinicsData from "../data/clinics.json";
import Dimmer from "./shared/Dimmer";

const Clinic = ({ data, language }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(clinicsData);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length >= 2) {
      const filtered = clinicsData.filter((item) => {
        // Check both mn and en data for matches
        const mnData = item.mn;
        const enData = item.en;

        return (
          (mnData.name && mnData.name.toLowerCase().includes(term)) ||
          (mnData.address && mnData.address.toLowerCase().includes(term)) ||
          (mnData.phone && mnData.phone.toLowerCase().includes(term)) ||
          (enData.name && enData.name.toLowerCase().includes(term)) ||
          (enData.address && enData.address.toLowerCase().includes(term)) ||
          (enData.phone && enData.phone.toLowerCase().includes(term))
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(clinicsData);
    }
  };

  return (
    <div className='container11 d-flex justify-content-center' id='clinics'>
      <div className='clinics-title fw-bold fs-3'>{data.title}</div>

      {loading ? <Dimmer/> : null}

      {errorMsg ? <div className="error-msg">
        <IoWarning/>
        &nbsp;
        {errorMsg}
      </div> : null}

      <input
        type='text'
        placeholder={data.placeholder}
        value={searchTerm}
        onChange={handleSearch}
        className='mt-3 mb-3 p-2 col-md-9 col-11 mb-5'
      />

      <div className='col-9'>
        {filteredData.map((item) => (
          <div key={"clinic-" + item.id} className='clinic-item'>
            <div className="clinic-img">
              <img
                src={item.logo}
                alt={item[language].name}
              />
            </div>
            <div className='clinic-info'>
              <div className="clinic-name">{item[language].name}</div>
              <p className='text-muted'>{item[language].address}</p>
              <p className='text-dark'>{item[language].phone}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clinic;
